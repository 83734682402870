export default [
  {
    path: "/",
    text: "home",
  },
  {
    path: "/strutture",
    text: "portfolio",
  },
  // {
  //   path: "/blog",
  //   text: "blog",
  // },
  {
    path: "/blog",
    text: "blog",
  },
  {
    path: "/contact",
    text: "contatti",
  },
]
